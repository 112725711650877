'use client'

import { AbTestingContext } from '@rentpath/ab-testing-react'
import { useEffect } from 'react'
import type { AbTestingData } from './types'

export function AbTestingProvider({
  children,
  abTestingData,
}: {
  children: React.ReactNode
  abTestingData: AbTestingData
}) {
  useEffect(() => {
    window.optimizelyFullStack = abTestingData.tagging
  }, [abTestingData])

  return (
    <AbTestingContext.Provider value={abTestingData}>
      {children}
    </AbTestingContext.Provider>
  )
}
