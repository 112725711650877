import Cookies from 'js-cookie'
import type { SessionParserConfig, AllClientParsers } from '../types/tracker'

const defaultConfig = {
  visitorKey: 'rp_visitor_id',
  sessionKey: 'rp_session_id',
  visitorAge: 86400 * 365 * 5 * 1000, // 5 years
  sessionAge: 30 * 60 * 1000, // 30 minutes
}

const makeRequestId = () =>
  new Date().getTime() + `00${Math.floor(Math.random() * 1000)}`.slice(-3)

const reducer =
  (config: Required<SessionParserConfig>) =>
  (dataToMergeWith: AllClientParsers) => {
    const cookies = Cookies.get()
    const requestId = makeRequestId()
    const visitorId = cookies[config.visitorKey] || requestId
    const sessionId = cookies[config.sessionKey] || requestId
    const timestamp = new Date().getTime()

    Cookies.set(config.visitorKey, visitorId, {
      expires: config.visitorAge
        ? new Date(timestamp + config.visitorAge)
        : new Date(),
    })

    Cookies.set(config.sessionKey, sessionId, {
      expires: config.sessionAge
        ? new Date(timestamp + config.sessionAge)
        : new Date(),
    })

    return {
      ...dataToMergeWith,
      visit: `${visitorId}.${sessionId}`,
      visitor: visitorId,
      session_id: sessionId,
    }
  }

export const session = (sessionParserConfig?: SessionParserConfig) => {
  if (sessionParserConfig) {
    return reducer({ ...defaultConfig, ...sessionParserConfig })
  }

  return reducer(defaultConfig)
}
