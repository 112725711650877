import Cookies from 'js-cookie'
import type { EcommerceParserConfig, AllClientParsers } from '../types/tracker'

import {
  createUniqueSubmissionCookieValue,
  isListingUniqueSubmission,
  getListingLeadSubmissionIdsCookieExpirationTime,
  UNIQUE_LEAD_SUBMISSION_COOKIE_NAME,
} from '../helpers/unique-lead-submission'

const defaultConfig = {
  campaignKey: 'campaign_id',
  sessionKey: 'rp_session_id',
}

const BASE_CIL = 0.02

function requestId(): number {
  return Date.now() + Math.floor((1 + Math.random()) * 1000)
}

const reducer =
  (_config: Required<Pick<EcommerceParserConfig, 'campaignKey'>>) =>
  (dataToMergeWith: AllClientParsers) => {
    if (
      !dataToMergeWith.action ||
      dataToMergeWith.action !== 'lead_submission'
    ) {
      return dataToMergeWith
    }

    const transactionId = requestId()
    const { listing_id, revenue, selection, cil, nv_rpl } = dataToMergeWith
    const cookies = Cookies.get()
    const uniqueSubmission = isListingUniqueSubmission({
      listingId: listing_id || '',
      listingLeadSubmissionIdsArrayAsString:
        cookies[UNIQUE_LEAD_SUBMISSION_COOKIE_NAME],
    })

    const cilNumber = Number(cil)
    const rplNumber = Number(nv_rpl)
    const transactionAdjustedTotal =
      cilNumber && rplNumber
        ? ((cilNumber * rplNumber) / BASE_CIL).toFixed(2)
        : undefined

    Cookies.set(
      UNIQUE_LEAD_SUBMISSION_COOKIE_NAME,
      createUniqueSubmissionCookieValue({
        listingLeadSubmissionIdsArrayAsString:
          cookies[UNIQUE_LEAD_SUBMISSION_COOKIE_NAME],
        listingId: listing_id || '',
      }),
      {
        domain: window.location.hostname,
        expires: getListingLeadSubmissionIdsCookieExpirationTime(),
      }
    )

    return {
      ...dataToMergeWith,
      transactionId,
      transactionAffiliation: selection,
      transactionAdjustedTotal,
      transactionTotal: revenue,
      uniqueSubmission: `${uniqueSubmission}`,
      transactionProducts:
        '[{sku:' +
        listing_id +
        ',name:' +
        listing_id +
        ',category:' +
        selection +
        ',price:' +
        revenue +
        ',quantity:1}]',
    }
  }

export const ecommerce = (ecommerceConfig?: EcommerceParserConfig) => {
  if (ecommerceConfig) {
    return reducer({
      ...defaultConfig,
      ...ecommerceConfig,
    })
  }

  return reducer(defaultConfig)
}
