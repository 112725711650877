import type { AllClientParsers } from '../types/tracker'

const reduce =
  (_config?: Record<string, never>) => (dataToMergeWith: AllClientParsers) => {
    if (
      typeof dataToMergeWith.event === 'object' &&
      (dataToMergeWith.event.pageX || dataToMergeWith.event.pageY)
    ) {
      return {
        ...dataToMergeWith,
        x_coordinate: dataToMergeWith.event.pageX,
        y_coordinate: dataToMergeWith.event.pageY,
      }
    }

    return dataToMergeWith
  }

export const event = (_config?: Record<string, never>) => {
  return reduce()
}
