import Cookies from 'js-cookie'
import {
  createUniqueSubmissionCookieValue,
  getListingLeadSubmissionIdsCookieExpirationTime,
  UNIQUE_LEAD_SUBMISSION_COOKIE_NAME,
} from '../helpers/unique-lead-submission'

type Callback = (args: unknown) => void

export interface Subscriptions {
  [eventType: string]: {
    [id: string]: Callback
  }
}

interface Unsubscribe {
  unsubscribe: () => void
}

export interface Dispatched {
  [eventType: string]: boolean
}

const subscriptions: Subscriptions = {}
const dispatchedSubscriptions: Dispatched = {}
let subscriptionIdCounter = 0

/**
 * @typedef {Object} Unsubscribe
 * @property {Function} unsubscribe - Unsubscribe from eventType
 *
 * @param {String} eventType - Name of the event to subscribe to
 * @param {Function} callback - Callback function to run once the event is triggered
 * @returns {Unsubscribe} Returns Unsubscribe object
 * @example
 *    subscribe('foo', () => console.log('foo triggered'))
 */
function subscribe(eventType: string, callback: Callback): Unsubscribe {
  const id = subscriptionIdCounter++

  if (!subscriptions[eventType]) {
    subscriptions[eventType] = {}
  }

  subscriptions[eventType][id] = callback

  if (dispatchedSubscriptions[eventType]) {
    callback(dispatchedSubscriptions[eventType])
  }

  return {
    unsubscribe: (): void => {
      delete subscriptions[eventType][id]

      if (Object.keys(subscriptions[eventType]).length === 0) {
        delete subscriptions[eventType]
      }
    },
  }
}

/**
 *
 * @param {String} eventType - Name of the event to dispatch
 * @returns void
 */
function dispatch(eventType: string): void {
  dispatchedSubscriptions[eventType] = true

  const leadsRequested = createUniqueSubmissionCookieValue({
    listingLeadSubmissionIdsArrayAsString: Cookies.get(
      UNIQUE_LEAD_SUBMISSION_COOKIE_NAME
    ),
  })

  //reset the expiration of the lead cookie to 30 mins
  Cookies.set(UNIQUE_LEAD_SUBMISSION_COOKIE_NAME, leadsRequested, {
    expires: getListingLeadSubmissionIdsCookieExpirationTime(),
    domain: window.location.hostname,
  })

  if (!subscriptions[eventType]) {
    return
  }

  Object.keys(subscriptions[eventType]).forEach((id): void =>
    subscriptions[eventType][id](subscriptions[eventType])
  )
}

if (typeof window !== 'undefined') {
  window.rentpathEventBus = {
    subscriptions,
    dispatchedSubscriptions,
    dispatch,
    subscribe,
  }
}

export { subscriptions, dispatchedSubscriptions, dispatch, subscribe }
