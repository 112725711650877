import type { CanTrack } from '../types/tracker'

export function normalizeEvent(
  eventName: string
): [string | undefined, string | undefined, CanTrack | undefined] {
  switch (eventName) {
    case 'click':
      return ['click', 'click', undefined]
    case 'select':
      return [
        'change',
        'select',
        (node: Node) => node && !!~['SELECT', 'OPTION'].indexOf(node.nodeName),
      ]
    default:
      return [undefined, undefined, undefined]
  }
}
