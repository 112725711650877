import type { AllClientParsers } from '../types/tracker'

interface InfoI {
  image?: string
  value?: string
}

interface GetAttrsI {
  href?: string
  element?: HTMLImageElement | HTMLInputElement | HTMLSelectElement
  listing_id?: string
  revenue?: string
  selection?: string
  tpl_source?: string
}

const defaultConfig = {
  tagPrefix: 'data-tag_',
}

function getStack(
  element: HTMLInputElement | HTMLImageElement | HTMLSelectElement
) {
  const stack = []
  // This is a hacky way to test for SVGElementInstance, hard to type
  let node = (element as any).correspondingElement || element

  while (node) {
    stack.push(node)
    node = node.parentNode
  }
  return stack.slice(0, -1).reverse()
}

function isInput(
  node: HTMLInputElement | HTMLImageElement | HTMLSelectElement
): node is HTMLInputElement {
  if (['SELECT', 'INPUT', 'TEXTAREA'].indexOf(node.nodeName) !== -1) {
    return true
  }

  return false
}

function isSelect(
  node: HTMLInputElement | HTMLImageElement | HTMLSelectElement
): node is HTMLSelectElement {
  if (node.nodeName === 'SELECT') {
    return true
  }

  return false
}

function getAtts(
  element: HTMLImageElement | HTMLInputElement | HTMLSelectElement
) {
  const atts: GetAttrsI = {}
  const href = element.getAttribute('href')

  if (href && !href.match(/^(?:javascript|#)/)) {
    atts.href = href
  }
  return atts
}

function getTags(element: Element) {
  const atts = element.attributes
  const prefix = defaultConfig.tagPrefix

  const tags: { [x: string]: string } = {}

  for (let i = 0; i < atts.length; i++) {
    if (
      atts[i] &&
      atts[i].name &&
      atts[i].name.substr(0, prefix.length) === prefix
    ) {
      tags[atts[i].name.substr(prefix.length)] = atts[i].value
    }
  }

  return tags
}

function getInfo(
  element: HTMLImageElement | HTMLInputElement | HTMLSelectElement
) {
  const info: InfoI = {}

  if ('src' in element) {
    info.image = element.src
  }

  if (isInput(element)) {
    if (isSelect(element)) {
      const node = element.options[element.selectedIndex]
      const attr = `${defaultConfig.tagPrefix}value`
      info.value = node.getAttribute(attr) || node.value
    } else {
      info.value = element.value
    }
  }

  return info
}

function getData(
  element: HTMLImageElement | HTMLInputElement | HTMLSelectElement
): InfoI & GetAttrsI {
  return getStack(element).reduce(
    (data, node) => ({
      ...data,
      ...getAtts(node),
      ...getTags(node),
    }),
    getInfo(element)
  )
}

// Hard to type this one because of ambiguity of attributes
const reduce =
  (_config?: Record<string, never>) =>
  (
    dataToMergeWith: AllClientParsers
  ): AllClientParsers | (AllClientParsers & InfoI & GetAttrsI) => {
    const node =
      dataToMergeWith.element ||
      (dataToMergeWith.event &&
        typeof dataToMergeWith.event !== 'string' &&
        dataToMergeWith.event.target)

    if (node) {
      return {
        ...dataToMergeWith,
        ...getData(
          node as HTMLImageElement | HTMLInputElement | HTMLSelectElement
        ),
      }
    }

    return dataToMergeWith
  }

export const element = <T extends object>(_elementReducerConfig?: T) => {
  return reduce()
}
