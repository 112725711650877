import { Host } from '../../../config/host'

import type { Credentials } from '../../../features/tagging/tagging.types'

// tagging profile
export const profile: Record<Host, string> = {
  [Host.rce]: 'apartment_guide',
  [Host.prod]: 'apartment_guide',
  [Host.re]: 'qa',
  [Host.qa]: 'qa',
  [Host.ci]: 'dev',
  [Host.local]: 'dev',
} as const

// tagging subprofile
export const subprofile: Record<Host, string> = {
  [Host.rce]: 'apartmentguide.com',
  [Host.prod]: 'apartmentguide.com',
  [Host.re]: 'qa.apartmentguide.com',
  [Host.qa]: 'qa.apartmentguide.com',
  [Host.ci]: 'ci.apartmentguide.com',
  [Host.local]: 'ci.apartmentguide.com',
}

// gtm setup options
export const credentials: Credentials = {
  standard: {
    id: 'GTM-NCWK9PZ',
    auth: {
      [Host.prod]: '0dhfYeT4lIItBEgVXCJYKQ',
      [Host.rce]: 'kyIUXh707-1v2Jqlmnq6bg',
      [Host.qa]: 'zJuBGICnXlwhFbE9YkowLw',
      [Host.re]: 'zJuBGICnXlwhFbE9YkowLw',
      default: 'RU12uCj6hbP6VJGYJISbWg',
    },
    preview: {
      [Host.prod]: 'env-2',
      [Host.rce]: 'env-1124',
      [Host.qa]: 'env-6',
      [Host.re]: 'env-6',
      default: 'env-8',
    },
  },
  gdpr: {
    id: 'GTM-MLQCVMM',
    auth: {
      [Host.prod]: '-5sKM0dDjdfnC3_BDhBPgg',
      [Host.qa]: '8DWcbP0JBNiQFDEabNKGcg',
      [Host.ci]: 'S37pRBixIntUdKQtqX2nDg',
      [Host.re]: 'YOYgQt-lJBM9ekL_HI-Syw',
      default: 'cqq6sOXZPv1rTxcIVHZbmQ',
    },
    preview: {
      [Host.prod]: 'env-2',
      [Host.qa]: 'env-98',
      [Host.ci]: 'env-99',
      [Host.re]: 'env-101',
      default: 'env-100',
    },
  },
}

export const measurementIds = {
  qa: 'XYGMV2JEF8',
  prod: 'LSGT55PFTP',
} as const

// GA Session ID map by host
export const gaSessionIdMap: Record<Host, string> = {
  [Host.rce]: measurementIds.prod,
  [Host.prod]: measurementIds.prod,
  [Host.re]: measurementIds.qa,
  [Host.qa]: measurementIds.qa,
  [Host.ci]: measurementIds.qa,
  [Host.local]: measurementIds.qa,
}
