import { uaParser } from '../utils/utils'

import type { DeviceParserConfig, AllClientParsers } from '../types/tracker'

const defaultConfig: Required<DeviceParserConfig> = {
  defaultType: 'desktop',
  enabledTypes: {
    desktop: true,
    mobile: true,
    tablet: true,
  },
}

// Switch is ALWAYS faster than an object map
// Object map looks prettier, but it is 6x slower
function osNormalize(
  type: 'pc' | 'mobile' | 'tablet'
): 'desktop' | 'mobile' | 'tablet' {
  switch (type) {
    case 'pc':
      return 'desktop'
    default:
      return type
  }
}

const reducer =
  (config: Required<DeviceParserConfig>) =>
  (dataToMergeWith: AllClientParsers) => {
    const parser = uaParser()
    const browser_type = osNormalize(parser.platform)

    return {
      ...dataToMergeWith,
      screen_type: config.enabledTypes[browser_type]
        ? browser_type
        : config.defaultType,
      screen_resolution: `${window.screen.width}x${window.screen.height}`,
      operating_system: parser.os.name,
      operating_system_version: (parser.os.version.info || '').split('.')[0],
    }
  }

export const device = (deviceParserConfig?: DeviceParserConfig) => {
  if (deviceParserConfig) {
    return reducer({
      ...defaultConfig,
      ...deviceParserConfig,
    })
  }

  return reducer(defaultConfig)
}
