import type { AllClientParsers } from '../types/tracker'

const gtmEventTranslation = {
  click: 'eventTrackerClick',
}

const reduce =
  (_config?: Record<string, never>) => (dataToMergeWith: AllClientParsers) => {
    if (!dataToMergeWith.action) {
      return dataToMergeWith
    }

    return {
      ...dataToMergeWith,
      event: `gtm.${
        gtmEventTranslation[`${dataToMergeWith.action}` as 'click'] ||
        `${dataToMergeWith.action}`
      }`,
      event_action: dataToMergeWith.action,
      ...(dataToMergeWith.item
        ? {
            event_label: dataToMergeWith.item,
          }
        : {}),
      ...(dataToMergeWith.section
        ? {
            event_category: dataToMergeWith.section,
          }
        : {}),
    }
  }

export const tagmanager = (_config?: Record<string, never>) => {
  return reduce()
}
