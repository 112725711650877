'use client'

import { useEffect, useRef } from 'react'

import type { TaggingData } from './tagging.types'

export function usePageViewTrack(getViewData: () => Record<string, unknown>) {
  const isTrackedRef = useRef(false)

  useEffect(() => {
    if (!isTrackedRef.current) {
      window.eventTracker?.view(getViewData())
      isTrackedRef.current = true
    }
  }, [getViewData])
}

/**
 *
 * @param getViewData the data to track
 * This function differes from usePageViewTrack as usePageViewTrack only executes once, usePageViewOnRouteChangeTrack executes every time the tracked data changes.
 * This is handy for tracking the views when a URL changes, ex: we filter/sort a property and the URL changes
 *
 */
export function usePageViewOnDataChangeTrack(
  getViewData: () => Record<string, unknown>
) {
  useEffect(() => {
    window.eventTracker?.view(getViewData())
  }, [getViewData])
}

export function PageViewTrack(props: { data: TaggingData }) {
  const data = props.data
  const isTrackedRef = useRef(false)

  useEffect(() => {
    if (!isTrackedRef.current) {
      window.eventTracker?.view(data)
      isTrackedRef.current = true
    }
  }, [data])

  return null
}
