import { uaParser } from '../utils/utils'

import type { AllClientParsers } from '../types/tracker'

const reducer =
  (_config?: Record<string, never>) => (dataToMergeWith: AllClientParsers) => {
    const parser = uaParser()

    return {
      ...dataToMergeWith,
      browser: parser.browser.name,
      browser_size: `${window.innerWidth}x${window.innerHeight}`,
      browser_version: (parser.browser.version.info || '').split('.')[0],
    }
  }

export const browser = (_config?: Record<string, never>) => {
  return reducer()
}
