export function recordMetric(payload: string, analyticsEndpoint: string) {
  if (navigator.sendBeacon) {
    navigator.sendBeacon(analyticsEndpoint, payload)
  } else {
    void fetch(analyticsEndpoint, {
      body: payload,
      method: 'POST',
      keepalive: true,
    })
  }
}
