// This barrel is fine, the idea is to not use index.js
// Grouping similar things together, and exporting named things
// is preffered.

export { device } from './device'
export { session } from './session'
export { browser } from './browser'
export { event } from './event'
export { element } from './element'
export { ecommerce } from './ecommerce'
export { tagmanager } from './tag-manager-event'
