import type { TrackerEvent, ClientParse, PartialConfig } from './types/tracker'

export const VIEW_ACTION = 'view'
export const IGNORE_ACTION = 'ignore'

const trackers: TrackerEvent[] = ['click', 'select']

const clientParsers: ClientParse[] = []

export const DEFAULT_CONFIG: PartialConfig = {
  trackers,
  clientParsers,
}
