/**
 * @type {import('next').NextConfig}
 */
module.exports = {
  trailingSlash: true,
  distDir: 'build/ag',
  headers: require('./headers'),
  rewrites: require('./rewrites'),
  redirects: require('./redirects'),
}
