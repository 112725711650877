export function sanitize<T extends object>(data: T): T {
  return Object.keys(data).reduce((sanitized, key) => {
    const obj = sanitized
    const val = (data as unknown as any)[key]

    if (val === 0 || Array.isArray(val) || (val && typeof val !== 'object')) {
      ;(obj as unknown as any)[key] = val.toString()
    }
    return obj
  }, {}) as any
}
