import Cookies from 'js-cookie'

function createCookie(
  name: string,
  value: string,
  minutes: number,
  domain: string = window.location.hostname
) {
  if (minutes) {
    const expires = new Date()

    expires.setTime(expires.getTime() + minutes * 60 * 1000)

    Cookies.set(name, value, {
      expires,
      domain,
    })
  } else {
    Cookies.set(name, value, {
      domain,
    })
  }
}

function readCookie(name: string) {
  return Cookies.get(name)
}

function eraseCookie(name: string, domain?: string) {
  if (domain) {
    return Cookies.remove(name, {
      domain,
    })
  }

  return Cookies.remove(name)
}

export default {
  createCookie,
  readCookie,
  eraseCookie,
}
