'use client'

import { Tracker } from '@rentpath/google-event-tracking'
import { useEffect, useMemo } from 'react'
import { Host } from '../request-data/host'
import { createTrackerConfig } from './google-event-tracker-config'

interface EventTrackerInitializeProps {
  useGDPR?: boolean
  hostEnv: Host
}

export function EventTrackerInitialize({
  useGDPR = false,
  hostEnv = Host.prod,
}: EventTrackerInitializeProps) {
  const config = useMemo(
    () => createTrackerConfig(useGDPR, hostEnv),
    [hostEnv, useGDPR]
  )

  useEffect(() => {
    if (window.eventTracker) {
      return
    }
    window.eventTracker = new Tracker(config)
  }, [config])

  return null
}
